import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
import { Button } from "semantic-ui-react";

function ExpandButton({ text, circular, onClick }) {
  return (
    <Button onClick={onClick} className={`btn__expand ${circular ? "circular" : ""}`}>
      <img src="/icons/caret-down-green.svg" alt="arrow down" className={`${circular ? "" : "mr-h"}`} />
      {!!text && <span>{text}</span>}
    </Button>
  );
}

ExpandButton.propTypes = {};

export default ExpandButton;

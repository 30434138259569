import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Image, Label, Table } from "semantic-ui-react";
import CONFIG from "constants/config";
import { useSelector, useDispatch } from "react-redux";
import { toastError } from "helpers/utils";
import { deleteTopic } from "redux/actions/topics";
import ConfirmationModal from "components/common/Modal/ConfirmationModal/ConfirmationModal";
import "./styles.scss";

const { apiUrl, xApiKey } = CONFIG;

const DeleteExpertise = ({ isEditable, peopleData, toggleExpertise, staffExpertiseId }) => {
  const { topicDeleting } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [isExpertiseSaveInProgress, setExpertiseSaveInProgress] = useState(false);

  const showDeletePopup = () => {
    setShowPopup(true);
  };

  const hideDeletePopup = () => {
    setShowPopup(false);
  };
  const deleteExpertiseRecord = () => {
    try {
      dispatch(
        deleteTopic({
          endpointUrl: `${apiUrl.linkApi}profile/${peopleData?.id}/expertise/${staffExpertiseId}`,
          data: {},
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    } catch (e) {
      toastError();
    }
    setExpertiseSaveInProgress(true);
    setShowPopup(false);
  };
  useEffect(() => {
    if (!topicDeleting && isExpertiseSaveInProgress) {
      toggleExpertise();
    }
  }, [topicDeleting]);

  return (
    <>
      <Table>
        <Table.Row verticalAlign="top">
          <>
            {isEditable ? (
              <Table.Cell className="deleteExpertiseIcon">
                <div className="d-flex justify-content-end">
                  <Label
                    data-testid="deleteRecord"
                    className="cursor-pointer"
                    onClick={showDeletePopup}
                    onKeyDown={showDeletePopup}
                    role="button"
                    tabIndex="0"
                  >
                    <Image src="/icons/delete-icon.svg" />
                  </Label>
                </div>
              </Table.Cell>
            ) : (
              <Table.Cell className="md-none">&nbsp;</Table.Cell>
            )}

            <ConfirmationModal
              isOpen={showPopup}
              modalMessage="Are you sure you want to delete this Expertise?"
              handleCancelClick={hideDeletePopup}
              handleContinueClick={deleteExpertiseRecord}
            />
          </>
        </Table.Row>
      </Table>
    </>
  );
};

DeleteExpertise.defaultProps = {
  isEditable: false,
  peopleData: {},
  toggleExpertise: () => {},
  staffExpertiseId: 0
};

DeleteExpertise.propTypes = {
  isEditable: PropTypes.bool,
  peopleData: PropTypes.shape(),
  toggleExpertise: PropTypes.func,
  staffExpertiseId: PropTypes.number
};

export default DeleteExpertise;

// withPopup.js

import React from "react";
import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";
import "./styles.scss";

const withPopup = (WrappedComponent) => {
  const HOC = ({ popupContent, ...props }) => {
    return (
      <Popup
        trigger={
          <div>
            <WrappedComponent {...props} />
          </div>
        }
        on={["hover", "focus"]}
        position="bottom left"
        className="custom-popup"
        inverted
      >
        {popupContent}
      </Popup>
    );
  };
  HOC.propTypes = {
    popupContent: PropTypes.node.isRequired
  };
  return HOC;
};

export default withPopup;

import { takeLatest } from "redux-saga/effects";

import { expertise } from "redux/constants";
import handleFetchExpertiseData from "../worker/handleFetchExpertiseData";

function* watchExpertiseData() {
  yield takeLatest(expertise.FETCH_EXPERTISE_DATA, handleFetchExpertiseData);
}

export default watchExpertiseData;

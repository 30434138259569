import Label from "components/common/ExpertiseLabel/Label";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dimmer, Loader, Modal, Segment } from "semantic-ui-react";
import { setUpdateTopicSuccess, updateTopic } from "redux/actions/topics";
import { setUpdateSkillSuccess } from "redux/actions/skills";
import { toastError } from "helpers/utils";
import { toast } from "react-semantic-toasts";
import CONFIG from "constants/config";
import LABEL from "constants/label";
import { getExpertise } from "redux/actions/expertise";
import BottomText from "./BottomText";
import "./styles.scss";

const { COMMON } = LABEL;

const DragAndDrop = ({ open, onModalClose, prevHandle, data }) => {
  const { apiUrl, xApiKey } = CONFIG;
  const dispatch = useDispatch();
  const {
    topicUpdating,
    topicUpdatingError,
    topicUpdatingSuccess,
    skillUpdatingSuccess,
    skillUpdatingError,
    peopleData
  } = useSelector((state) => state);

  const [items, setItems] = useState(data);
  const [boxes, setBoxes] = useState([
    {
      id: 101,
      level: "Thought Leader",
      title:
        "7+ years pre-BCG experience/post grad educational experience OR 10+ BCG cases OR 60+ months experience within the topic",
      info: "hello",
      items: [],
      proficiency: 1
    },
    {
      id: 102,
      level: "Advance",
      title:
        "4-6 years pre-BCG experience/post grad educational experience OR 8+ BCG cases OR 30+ months experience within the topic ...",
      info: "hello",
      items: [],
      proficiency: 2
    },
    {
      id: 103,
      level: "Experienced",
      title:
        "2-3 years pre-BCG experience/post grad educational experience OR 4+ BCG cases OR 18+ months experience within the topic",
      info: "hello",
      items: [],
      proficiency: 3
    },
    {
      id: 104,
      level: "Basic",
      title:
        "0-2 years pre-BCG experience/post grad educational experience OR 2+ BCG cases OR 6+ months experience within the topic",
      info: "hello",
      items: [],
      proficiency: 4
    }
  ]);

  useEffect(() => {
    if (topicUpdatingSuccess || skillUpdatingSuccess) {
      toast({
        type: "success",
        icon: COMMON.SuccessMsgIcon,
        title: COMMON.SuccessMsgHeader,
        description: COMMON.ExpertiseSaveSuccessMsg,
        animation: "fly left",
        time: 15000
      });
      dispatch(setUpdateTopicSuccess());
      dispatch(setUpdateSkillSuccess());
      dispatch(
        getExpertise({
          endpoint: `${apiUrl.linkApi}profile/${peopleData?.id}/expertise-paaf-tpoc?page=${1}&pageSize=${10}`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
      onModalClose();
    }
    if (topicUpdatingError || skillUpdatingError) {
      toastError();
    }
  }, [topicUpdatingSuccess, topicUpdatingError]);

  const handleSubmit = () => {
    const expertiseList = boxes.reduce((acc, curr) => {
      return [...acc, ...(curr.items?.filter((item) => item?.isSkill === false) ?? [])];
    }, []);

    const skillList = boxes.reduce((acc, curr) => {
      return [...acc, ...(curr.items?.filter((item) => item?.isSkill) ?? [])];
    }, []);

    const expertisePayload = expertiseList.map(({ id, proficiency }) => {
      return { expertiseGuid: id, proficiency, designatedBy: 1, createdFrom: 1, lastUpdateFrom: 1 };
    });

    const skillPayload = skillList.map(({ id, proficiency }) => {
      return { skillGuid: id, proficiency, designatedBy: 1, createdFrom: 1, lastUpdateFrom: 1 };
    });

    if (expertisePayload?.length) {
      dispatch(
        updateTopic({
          endpoint: `${apiUrl.linkApi}profile/${peopleData?.id}/expertise`,
          data: {
            expertise: [...expertisePayload]
          },
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
    if (skillPayload?.length) {
      dispatch(
        updateTopic({
          endpoint: `${apiUrl.linkApi}profile/${peopleData?.id}/skill`,
          data: {
            skill: [...skillPayload]
          },
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
  };

  const handleDragStart = (e, itemId) => {
    e.dataTransfer.setData("text/plain", itemId);
  };

  const handleDrop = (e, boxId) => {
    e.preventDefault();
    const itemId = e.dataTransfer.getData("text/plain");
    const item = items.find((item) => item.id.toString() === itemId);

    if (item) {
      const updatedItems = items.filter((i) => i.id !== item.id);
      setItems(updatedItems);

      const updatedBoxes = boxes.map((box) =>
        box.id === boxId ? { ...box, items: [...box.items, { ...item, proficiency: box?.proficiency }] } : box
      );
      setBoxes(updatedBoxes);
    }
  };

  const handleRemoveFromBox = (boxId, itemId) => {
    const updatedBoxes = boxes.map((box) =>
      box.id === boxId ? { ...box, items: box.items.filter((item) => item.id !== itemId) } : box
    );
    setBoxes(updatedBoxes);

    const item = boxes.find((box) => box.id === boxId)?.items.find((item) => item.id === itemId);
    if (item) {
      setItems((prevItems) => [...prevItems, item]);
    }
  };

  const handleClose = () => {
    onModalClose();
  };

  return (
    <div>
      <Modal open={open} onClose={onModalClose} className="addExpertise_secPopup">
        <Modal.Content>
          {topicUpdating ? (
            <Segment className="loading__segment">
              <Dimmer active inverted>
                <Loader size="large">Saving...</Loader>
              </Dimmer>
            </Segment>
          ) : null}
          <div className="addExpertise__popup-heading">
            <div>
              <h1>Add your Expertise in just two steps</h1>
            </div>
            <div>
              <img
                src="./icons/cross-icon-black.svg"
                onClick={handleClose}
                className="cancel_icon"
                alt="close"
                title="close"
              />
            </div>
          </div>
          <h1>Drag and Drop expertise to level of profiencies</h1>
          <div className="expertise_section">
            {items.map((item) => {
              return (
                <div key={item.id} draggable onDragStart={(e) => handleDragStart(e, item.id)}>
                  <Label name={item?.name} />
                </div>
              );
            })}
          </div>
          <div>
            <div className="expertise_Level_Box">
              {boxes.map((box) => (
                <div
                  className="dashed_border"
                  key={box.id}
                  onDrop={(e) => handleDrop(e, box.id)}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <p className="level_name">{box.level}</p>

                  <p>
                    <img src="./icons/info-circle.png" alt="info" title="info" />
                    {box.title}
                  </p>

                  {box.items.length ? "" : <p className="dragDrop">Drag and drop here</p>}

                  {box.items.map((item) => (
                    <div key={item.id} onClick={() => handleRemoveFromBox(box.id, item.id)} style={{ border: "none" }}>
                      <Label name={item?.name} minus="true" />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="align-items-center text-align-center">
            <Button className="previous_button" onClick={prevHandle}>
              Previous step
            </Button>
            <Button className="previous_button" onClick={handleSubmit} disabled={items?.length}>
              Submit Expertise
            </Button>
          </div>
          <BottomText />
        </Modal.Content>
      </Modal>
    </div>
  );
};
DragAndDrop.defaultProps = {
  open: false,
  onModalClose: () => {},
  prevHandle: () => {}
};
DragAndDrop.propTypes = {
  open: PropTypes.bool,
  onModalClose: PropTypes.func,
  prevHandle: PropTypes.func
};

export default DragAndDrop;

import { expertise } from "redux/constants";

const expertiseDataReducer = (state = [], action) => {
  switch (action.type) {
    case expertise.FETCH_EXPERTISE_DATA_SUCCESS: {
      return [action.data];
    }
    default:
      return state;
  }
};
export default expertiseDataReducer;

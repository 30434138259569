import React from "react";
import PropTypes from "prop-types";
import { Container, Divider, List, ListItem } from "semantic-ui-react";
import TaxonomyTerm from "../TaxonomyTerm/TaxonomyTerm";
import SearchResultHeader from "./SearchResultHeader/SearchResultHeader";

export default function TaxonomySearchResults({ terms, apiConfig, onAddTerm, onRemoveTerm, dispatch, prevAddedTerms }) {
  return (
    <Container className="search__results">
      {terms?.map((result, index) => (
        <>
          <section key={index}>
            <SearchResultHeader text={result?.title} infoText={result?.infoText} />
            <List horizontal>
              {result?.termHints.map((term) => (
                <ListItem key={term?.id}>
                  <TaxonomyTerm
                    term={{ name: term?.name, id: term?.id, isAdded: term?.isAdded, isSkill: term?.isSkill }}
                    onAddTerm={onAddTerm}
                    onRemoveTerm={onRemoveTerm}
                    dispatch={dispatch}
                    prevAddedTerms={prevAddedTerms}
                    apiConfig={apiConfig}
                    termTbdb={result?.tbdb}
                  />
                </ListItem>
              ))}
            </List>
          </section>
          {index !== (terms?.length ?? 0) - 1 && <Divider />}
        </>
      ))}
    </Container>
  );
}

TaxonomySearchResults.defaultProps = {
  prevAddedTerms: []
};

TaxonomySearchResults.propTypes = {
  prevAddedTerms: PropTypes.arrayOf(PropTypes.shape({})),
  dispatch: PropTypes.func.isRequired,
  onAddTerm: PropTypes.func.isRequired,
  onRemoveTerm: PropTypes.func.isRequired,
  terms: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      infoText: PropTypes.string,
      termHints: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          isAdded: PropTypes.bool,
          isSkill: PropTypes.bool
        })
      )
    })
  ).isRequired
};

import { expertise } from "redux/constants";

const expertiseDataLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case expertise.FETCH_EXPERTISE_DATA:
      return true;
    case expertise.FETCH_EXPERTISE_DATA_SUCCESS:
    case expertise.FETCH_EXPERTISE_DATA_FAILURE:
      return false;

    default:
      return state;
  }
};
export default expertiseDataLoadingReducer;

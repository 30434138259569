import PropTypes from "prop-types";

import { Grid } from "semantic-ui-react";
import Masonry from "react-masonry-css";
import ProfileSummary from "components/ProfileSummary";
import Experience from "components/Experience";
import Education from "components/Education/Education";
import UpdateOverview from "components/UpdateOverview";
import Achievements from "components/Achievements";
import About from "components/About/About";

import { useSelector } from "react-redux";

import Expertise from "components/Expertise";
import OrgStructure from "components/OrgStructure";

const DesktopMobileMasonary = ({
  isProfileLoading,
  showElement,
  isEditable,
  allCases,
  ssCasesLoading,
  materialsData,
  ssMaterialLoadingBatch,
  isAboutHasValue,
  setLinkedInModalOpen,
  showMetrics,
  loggedInUserHrId
}) => {
  const { profile, peopleData, peopleDataError, peopleDataLoading } = useSelector((state) => state);

  const breakpointColumnsObj = {
    default: 2,
    1023: 1
  };
  return (
    <Masonry breakpointCols={breakpointColumnsObj} className="masonry-grid" columnClassName="masonry-grid__column">
      <ProfileSummary
        className="mobile-order-2 profile-summury-card"
        user={profile}
        isLoading={isProfileLoading}
        isEditable={isEditable}
        languageData={peopleData?.languages}
        loggedInUserHrId={loggedInUserHrId}
      />
      {showMetrics && (
        <UpdateOverview
          className="mobile-order-1"
          profile={profile}
          isLoading={isProfileLoading || ssCasesLoading}
          cases={allCases}
          isAboutHasValue={isAboutHasValue}
          isSSCasesLoadingBatch={ssCasesLoading}
          materials={materialsData}
          isSSMaterialLoadingBatch={ssMaterialLoadingBatch}
          loggedInUserHrId={loggedInUserHrId}
        />
      )}
      {/* empty div to fix for react masanory position */}
      {!!isAboutHasValue && <div />}
      {!!isAboutHasValue && (
        <About
          className="mobile-order-3"
          profile={profile}
          isEditable={isEditable}
          loggedInUserHrId={loggedInUserHrId}
        />
      )}

      <Expertise className="mobile-order-5" user={profile} isEditable={isEditable} peopleData={peopleData} />
      <></>
      <> {showElement} </>
      <Experience
        className="mobile-order-6"
        isEditable={isEditable}
        isLoading={isProfileLoading || peopleDataLoading}
        experience={peopleData?.workExperience}
        workdayURL={peopleData?.workerURL}
        setLinkedInModalOpen={setLinkedInModalOpen}
        loggedInUserHrId={loggedInUserHrId}
      />
      <Grid className="mobile-order-6 profile-2col-grid">
        <Grid.Row>
          <Grid.Column computer={8} mobile={16} tablet={16}>
            <Education
              isEditable={isEditable}
              isLoading={isProfileLoading || peopleDataLoading}
              education={peopleData?.education}
              setLinkedInModalOpen={setLinkedInModalOpen}
              loggedInUserHrId={loggedInUserHrId}
            />
          </Grid.Column>
          <Grid.Column computer={8} mobile={16} tablet={16}>
            <Achievements isEditable={isEditable} isLoading={isProfileLoading || peopleDataLoading} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* {renderComponentOrLoader(
        <Expertise className="mobile-order-5" summary={expertiseSummary} isEditable={isEditable} />,
        {
          title: "Expertise",
          className: "mobile-order-5"
        }
      )}
      <></> */}
      {peopleData?.alumni?.toLowerCase() === "no" && (
        <OrgStructure
          profile={profile}
          isLoading={isProfileLoading || peopleDataLoading}
          orgError={peopleDataError}
          organization={peopleData}
        />
      )}
    </Masonry>
  );
};

DesktopMobileMasonary.defaultProps = {
  isProfileLoading: false,
  showElement: false,
  isEditable: false,
  allCases: [],
  ssCasesLoading: false,
  materialsData: [],
  ssMaterialLoadingBatch: false,
  isAboutHasValue: false,
  showMetrics: false,
  loggedInUserHrId: null,
  setLinkedInModalOpen: () => {}
};

DesktopMobileMasonary.propTypes = {
  isProfileLoading: PropTypes.bool,
  showElement: PropTypes.bool,
  isEditable: PropTypes.bool,
  allCases: PropTypes.arrayOf(Object),
  ssCasesLoading: PropTypes.bool,
  materialsData: PropTypes.arrayOf(Object),
  ssMaterialLoadingBatch: PropTypes.bool,
  isAboutHasValue: PropTypes.bool,
  showMetrics: PropTypes.bool,
  loggedInUserHrId: PropTypes.number,
  setLinkedInModalOpen: PropTypes.func
};

export default DesktopMobileMasonary;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown, Table } from "semantic-ui-react";
import { getProficiency } from "pages/AffiliationsPage/helper/utils";
import CONFIG from "constants/config";
import "pages/AffiliationsPage/styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { isEmptyOrNull, toastError, toastSuccess } from "helpers/utils";

import { updateTopic } from "redux/actions/topics";

import "./styles.scss";

const { apiUrl, xApiKey } = CONFIG;

const ExpertiseProficiency = ({
  isEditable,
  expertiseProficiency,
  expertiseGuid,
  peopleData,
  designatedBy,
  staffExpertiseId,
  toggleExpertise
}) => {
  const { topicUpdating, topicUpdatingError, skillUpdatingError } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { proficiency } = useSelector((state) => state);
  const [isExpertiseSaveInProgress, setExpertiseSaveInProgress] = useState(false);

  let options = [];
  if (proficiency?.length > 0) {
    proficiency.map(({ proficiencyId, name: proficiencyName, sort_order: order }) =>
      options.push({
        key: proficiencyId,
        value: proficiencyId,
        order,
        text: proficiencyName
      })
    );
  }
  options = options?.sort((a, b) => a.order - b.order);

  const getProficiencyId = () => {
    let proficiencyId = "";
    if (expertiseProficiency) {
      proficiencyId = expertiseProficiency;
    }
    return proficiencyId;
  };

  const proficiencyId = getProficiencyId();

  const onValueChange = async (e, data) => {
    // Use a callback to ensure the state is updated before proceeding
    const payload = [];
    payload.push({
      expertiseGuid,
      proficiency: data.value,
      staffExpertiseId,
      designatedBy,
      createdFrom: 1,
      lastUpdateFrom: 1
    });
    setExpertiseSaveInProgress(true);
    dispatch(
      updateTopic({
        endpoint: `${apiUrl.linkApi}profile/${peopleData.id}/expertise`,
        data: {
          expertise: payload
        },
        config: {
          headers: {
            "x-api-key": `${xApiKey.profile}`
          }
        }
      })
    );
  };

  useEffect(() => {
    if (!topicUpdating && isExpertiseSaveInProgress) {
      if (isEmptyOrNull(topicUpdatingError) && isEmptyOrNull(skillUpdatingError)) {
        toastSuccess();
        toggleExpertise();
        setExpertiseSaveInProgress(false);
      } else {
        toastError();
      }
    }
  }, [topicUpdating, topicUpdatingError, isExpertiseSaveInProgress]);

  return (
    <>
      <Table>
        <Table.Row verticalAlign="top">
          <Table.Cell className={isEditable ? "table__customCss--editableMode" : ""}>
            {isEditable ? (
              <Dropdown
                className="table__customCss--dropDown"
                placeholder="Select Proficiency"
                fluid
                selection
                options={options}
                defaultValue={proficiencyId}
                icon=""
                onChange={(e, data) => onValueChange(e, data)}
              />
            ) : (
              getProficiency(expertiseProficiency, isEditable)
            )}
          </Table.Cell>

          <></>
        </Table.Row>
      </Table>
    </>
  );
};

ExpertiseProficiency.defaultProps = {
  isEditable: false,
  expertiseProficiency: "",
  expertiseGuid: "",
  designatedBy: "",
  staffExpertiseId: "",
  toggleExpertise: () => {}
};

ExpertiseProficiency.propTypes = {
  isEditable: PropTypes.bool,
  expertiseProficiency: PropTypes.string,
  expertiseGuid: PropTypes.string,
  peopleData: PropTypes.shape().isRequired,
  designatedBy: PropTypes.string,
  staffExpertiseId: PropTypes.string,
  toggleExpertise: PropTypes.func
};

export default ExpertiseProficiency;

import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon, Label } from "semantic-ui-react";

import "./styles.scss";
import axios from "axios";

function TaxonomyTerm({ term, termTbdb, apiConfig, onAddTerm, onRemoveTerm, prevAddedTerms, dispatch }) {
  const getTermPath = useCallback(
    async (id) => {
      const endpoint = `${apiConfig?.apiEndpoint}?TBDB=${termTbdb}&service=term&template=service.json&id=${id}`;
      try {
        return await axios.get(endpoint, { headers: apiConfig?.headers });
      } catch (error) {
        console.error(`Failed to fetch data for ${termTbdb} with term ${id}:`, error);
        throw error; // Re-throw the error to be handled by the caller
      }
    },
    [term?.id]
  );

  useEffect(() => {
    if (prevAddedTerms?.length) {
      prevAddedTerms?.forEach((item) => {
        const action = {
          type: "UPDATE_TERM",
          term: item
        };
        dispatch(action);
      });
    }
  }, [prevAddedTerms]);

  const handleOnTermClick = async () => {
    if (term?.isAdded) {
      onRemoveTerm(term);
      dispatch({
        type: "UPDATE_TERM",
        term: { ...term, isAdded: false }
      });
    } else {
      const termDetails = await getTermPath(term?.id);
      onAddTerm({ ...term, isAdded: !term?.isAdded, path: termDetails?.data?.terms[0]?.term?.paths[0]?.path });
    }
  };
  return (
    <Label className={`taxonomy__term--label ${term?.isAdded && "added"}`} onClick={handleOnTermClick}>
      {(!term?.isAdded && <Icon name="plus circle" />) || <Icon name="minus circle" />}
      {term?.name || ""}
    </Label>
  );
}

TaxonomyTerm.defaultProps = {
  term: {},
  prevAddedTerms: [],
  onAddTerm: () => {},
  onRemoveTerm: () => {}
};

TaxonomyTerm.propTypes = {
  term: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isAdded: PropTypes.bool,
    isSkill: PropTypes.bool
  }),
  prevAddedTerms: PropTypes.arrayOf(PropTypes.shape({})),
  dispatch: PropTypes.func.isRequired,
  onAddTerm: PropTypes.func,
  onRemoveTerm: PropTypes.func,
  termTbdb: PropTypes.string.isRequired
};

export default TaxonomyTerm;

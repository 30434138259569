import PropTypes from "prop-types";
import axios from "axios";
import { useCallback, useEffect, useReducer, useState } from "react";
import { Input } from "semantic-ui-react";
import { debounce } from "lodash";
import TaxonomySearchResult from "./TaxonomySearchResult/TaxonomySearchResult";
import TaxonomySearchReducer, { initialState } from "./TaxonomySearchReducer";
import "./styles.scss";

export default function TaxonomySearch({
  placeholder,
  apiConfig,
  tbdbList,
  onAddTerm,
  onRemoveTerm,
  setTaxonomyActionDispatch,
  prevAddedTerms
}) {
  const { apiEndpoint, headers } = apiConfig;
  const [keyword, setKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [searchQueryResults, dispatch] = useReducer(TaxonomySearchReducer, initialState);

  const { searchResults = [] } = searchQueryResults;

  const queryTaxonomy = async (searchTerm, tbdb) => {
    const endpoint = `${apiEndpoint}?TBDB=${tbdb}&service=prefix&term_prefix=${searchTerm}&template=service.json`;

    try {
      return await axios.get(endpoint, { headers });
    } catch (error) {
      console.error(`Failed to fetch data for ${tbdb} with term ${searchTerm}:`, error);
      throw error; // Re-throw the error to be handled by the caller
    }
  };

  useEffect(() => {
    setTaxonomyActionDispatch({ dispatch });
  }, [dispatch]);

  useEffect(() => {
    if (!keyword) {
      const action = {
        type: "SET_SEARCH_RESULTS",
        searchResults: []
      };
      dispatch(action);
    }
  }, [keyword]);

  const searchTaxonomy = useCallback(
    debounce(async (searchTerm) => {
      if (!searchTerm) return;
      setIsLoading(true);
      const batch = tbdbList?.map((item) => queryTaxonomy(searchTerm, item?.tbdb));
      let action;
      try {
        const results = await Promise.allSettled(batch);
        const resultData = tbdbList?.reduce((acc, curr, index) => {
          if (results[index].status === "fulfilled") {
            const {
              data: { termHints = [] } = {} // Add nullish coalescing operator to provide a default value
            } = results[index]?.value ?? {}; // Add nullish coalescing operator to provide a default value
            const terms = { termHints, title: curr?.title, tbdb: curr?.tbdb };
            acc.push(terms);
          } else {
            console.error(`Error fetching search results for ${curr?.tbdb}:`, results[index]?.reason);
          }
          return acc;
        }, []);
        resultData.forEach((item) => {
          if (Array.isArray(item?.termHints)) {
            // Map over termHints to add isAdded: false to each termHint
            item.termHints = item?.termHints.map((termHint) => ({
              ...termHint,
              isAdded: false,
              isSkill: item?.tbdb === "ToolsAndSolutions"
            }));
          }
        });
        action = {
          type: "SET_SEARCH_RESULTS",
          searchResults: resultData?.filter((item) => item?.termHints?.length > 0)
        };
        // setSearchResults(resultData?.filter((item) => item?.termHints?.length > 0));
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching search results", error);
      } finally {
        dispatch(action);
        setIsLoading(false);
      }
    }, 500),
    [tbdbList]
  );

  const handleSearch = useCallback(
    (e) => {
      const searchTerm = e?.target?.value;
      setKeyword(searchTerm);
      searchTaxonomy(searchTerm);
    },
    [searchTaxonomy]
  );

  return (
    <div className="taxonomy__search">
      <Input
        fluid
        size="big"
        icon="search"
        iconPosition="left"
        placeholder={placeholder}
        className="taxonomy__search"
        onChange={handleSearch}
        loading={isLoading}
      />
      {!isLoading && keyword && !!searchResults?.length && (
        <TaxonomySearchResult
          terms={searchResults}
          onAddTerm={onAddTerm}
          onRemoveTerm={onRemoveTerm}
          dispatch={dispatch}
          prevAddedTerms={prevAddedTerms}
          apiConfig={apiConfig}
        />
      )}
    </div>
  );
}

TaxonomySearch.defaultProps = {
  placeholder: "Search",
  prevAddedTerms: []
};

TaxonomySearch.propTypes = {
  placeholder: PropTypes.string,
  apiConfig: PropTypes.shape({
    headers: PropTypes.shape({
      authorization: PropTypes.string.isRequired,
      noAuthHeader: PropTypes.bool.isRequired
    }),
    apiEndpoint: PropTypes.string.isRequired
  }).isRequired,
  tbdbList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      tbdb: PropTypes.string.isRequired
    })
  ).isRequired,
  prevAddedTerms: PropTypes.arrayOf(PropTypes.shape({})),
  setTaxonomyActionDispatch: PropTypes.func.isRequired,
  onAddTerm: PropTypes.func.isRequired,
  onRemoveTerm: PropTypes.func.isRequired
};

import IconWithImageAndPopover from "components/common/IconWithImageAndPopover";
import IconWithImage from "components/common/IconWithImage";
import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS } from "analytics/constants";
import LABEL from "constants/label";
import CONFIG from "constants/config";
import { isEmptyOrNull } from "helpers/utils";
import moment from "moment";
import Cookies from "js-cookies";

const { linkedInReleaseRegions, linkedInReleaseOffices, linkedInSuperUsers, linkedInRegionCodeSwitch } = CONFIG;

export const colleagueIcons = (Component, EmailComponent, profile) => {
  return [
    <div
      data-inverted
      data-tooltip={
        profile?.isAnonymize || profile?.status?.includes("Terminated") ? null : LABEL.BANNER.TOOLTIPS.Phone
      }
      className={
        profile?.isAnonymize || profile?.status?.includes("Terminated") ? "btn-disabled phone-tip" : "phone-tip"
      }
    >
      <IconWithImageAndPopover
        size="medium"
        imgSrc="/icons/phone.svg"
        altText="Phone"
        callback={() => {
          sendEventData(TRIGGERS.BANNER.PHONE, EVENTS.BANNER_CLICKS); // Analytics Event
        }}
      >
        {Component}
      </IconWithImageAndPopover>
    </div>,
    <div
      data-tooltip={
        profile?.isAnonymize || profile?.status?.includes("Terminated") ? null : LABEL.BANNER.TOOLTIPS.Email
      }
      data-inverted
      className={
        profile?.isAnonymize || profile?.status?.includes("Terminated") ? "btn-disabled banner-email" : "banner-email"
      }
    >
      <IconWithImageAndPopover
        size="medium"
        withPointer
        imgSrc="/icons/mail-green.svg"
        altText="Email"
        callback={() => {
          sendEventData(TRIGGERS.BANNER.EMAIL, EVENTS.BANNER_CLICKS); // Analytics Event
        }}
      >
        {EmailComponent}
      </IconWithImageAndPopover>
    </div>,
    <div
      data-tooltip={
        profile?.isAnonymize || profile?.status?.includes("Terminated") ? null : LABEL.BANNER.TOOLTIPS.Slack
      }
      data-inverted
      className={profile?.isAnonymize || profile?.status?.includes("Terminated") ? "btn-disabled" : null}
    >
      <IconWithImage
        iconSize="medium"
        imgSrc="/icons/slack-white.svg"
        backgroundSize="28px 28px"
        href={`https://nc-global-bcg.slack.com/app_redirect?channel=${profile?.slackId}`}
        id="slack"
        callback={() => {
          sendEventData(TRIGGERS.BANNER.SLACK, EVENTS.BANNER_CLICKS); // Analytics Event
        }}
      />
    </div>
  ];
};

export const selfIcons = (Component) => {
  return [
    <div data-tooltip={LABEL.BANNER.TOOLTIPS.Phone} data-inverted className="phone-tip">
      <IconWithImageAndPopover
        size="medium"
        imgSrc="/icons/phone.svg"
        altText="Phone"
        callback={() => {
          sendEventData(TRIGGERS.BANNER.PHONE, EVENTS.BANNER_CLICKS); // Analytics Event
        }}
      >
        {Component}
      </IconWithImageAndPopover>
    </div>
  ];
};

export const checkEditable = (user, profile) => {
  const {
    canEditPersonalProfile,
    canEditColleagueProfile,
    canEditLimitedProfile,
    canEditAboutEct,
    canEditAboutSA,
    canEditAboutKT,
    canEditAboutCCO
  } = { ...user?.permissions };
  return (
    user?.id === profile?.id ||
    user?.id === profile?.assistantStaffId ||
    canEditPersonalProfile ||
    canEditColleagueProfile ||
    canEditLimitedProfile ||
    canEditAboutEct ||
    canEditAboutSA ||
    canEditAboutKT ||
    canEditAboutCCO
  );
};

export const isLinkedInReleaseRegion = (hostOfficeRegion) => {
  return !!linkedInReleaseRegions.includes(hostOfficeRegion);
};

export const isLinkedInReleaseOffice = (hostOfficeId) => {
  return !!linkedInReleaseOffices.includes(hostOfficeId);
};

export const doesProfileHaveOtherInExp = (experience = []) => {
  const expOthers = experience?.filter((exp) => exp?.company?.includes("Other"));
  return !!expOthers.length;
};

export const isLinkedInSuperUser = (hrid) => {
  return !!linkedInSuperUsers?.includes(hrid?.toString());
};

export const showLinkedInFeature = (peopleData) => {
  if (linkedInRegionCodeSwitch) {
    return (
      (isLinkedInReleaseRegion(peopleData?.hostOfficeRegion) &&
        (doesProfileHaveOtherInExp(peopleData?.workExperience) || isEmptyOrNull(peopleData?.workExperience)) &&
        peopleData?.status === "Regular-Active") ||
      isLinkedInSuperUser(peopleData?.id)
    );
  }
  return true;
};

export const isUserAlumni = (isAlumni) => {
  return isAlumni === "YES";
};
export const getCookie = (cookieName) => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");

  for (let i = 0; i < cookieArray.length; i += 1) {
    const cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null; // Return null if the cookie is not found
};

export const setCookie = (name, value, days) => {
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=  ${d.toUTCString()}`;
  document.cookie = `${name}=${value};${expires}`;
};

export const isRemindMeLater = (noOfSkips) => {
  const skipCount = getCookie(LABEL.LINKEDIN.SAVE_SKIP_COUNT);
  if (!skipCount) {
    setCookie(LABEL.LINKEDIN.SAVE_SKIP_COUNT, noOfSkips);
  }
  const timeExpiration = getCookie(LABEL.LINKEDIN.TIME_EXPIRATION);
  if (!timeExpiration) return true;
};

export const getParsedCookie = (OktaToken) => {
  const rawToken = Cookies.getItem(OktaToken);
  const oktaToken = rawToken ? JSON.parse(rawToken) : null;
  return oktaToken;
};

export const isTokenExpired = (token) => {
  if (token) {
    const now = new Date();
    /**
     * JWT token time format is number of seconds from 1970-01-01T00:00:00Z. To convert it into correct time,
     * it needs to be converted into milliseconds as JavaScript Date support milliseconds since 1970-01-01T00:00:00Z
     * */
    const oted = new Date(Number(token?.expiresAt) * 1000);
    return now - oted >= 0;
  }
  return false;
};

export const removeCookie = (key) => {
  Cookies.removeItem(key);
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Breadcrumb } from "semantic-ui-react";
import "./styles.scss";

const TermPath = ({ path }) => {
  const [termPath, setTermPath] = useState([]);

  useEffect(() => {
    if (path) {
      setTermPath(path?.reduce((acc, curr) => [...acc, { key: curr?.field?.name, content: curr?.field?.name }], []));
    }
  }, [path]);

  return (
    <div className="term__path">
      <Breadcrumb icon="right angle" sections={termPath} />
    </div>
  );
};

TermPath.propTypes = {
  path: PropTypes.arrayOf(PropTypes.shape({ field: PropTypes.shape({ name: PropTypes.string }) })).isRequired
};

export default TermPath;

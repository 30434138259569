import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Card } from "semantic-ui-react";
import AccordionTable from "components/common/AccordionTable";
import AddExpertise from "components/AddExpertise";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { getProficiency } from "redux/actions/proficiency";
import { getDesignatedBy } from "redux/actions/designatedBy";
import CONFIG from "constants/config";

function Expertise({ user, isEditable, peopleData }) {
  const { apiUrl, xApiKey } = CONFIG;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleAddExpertise = () => {
    setOpen(true);
  };

  useEffect(() => {
    dispatch(
      getDesignatedBy({
        endpoint: `${apiUrl.linkApi}profile/ref/designatedby`,
        config: {
          headers: {
            "x-api-key": `${xApiKey.profile}`
          }
        }
      })
    );
    dispatch(
      getProficiency({
        endpoint: `${apiUrl.linkApi}profile/ref/proficiency`,
        config: {
          headers: {
            "x-api-key": `${xApiKey.profile}`
          }
        }
      })
    );
  }, [peopleData, dispatch]);
  return (
    <Card className="expertise_card">
      <Card.Content>
        <div className="Heading_section d-flex justify-content-between align-items-center">
          <div>
            <Card.Header>Expertise</Card.Header>
            <Card.Meta>Sorted by most recent case</Card.Meta>
          </div>
          <div>
            <Button className="add__expertise--btn">Add Excel</Button>
            <Button className="add__expertise--btn" onClick={handleAddExpertise}>
              Add Expertise
            </Button>
          </div>
        </div>
        {open && <AddExpertise open={open} onModalClose={() => setOpen(false)} />}
      </Card.Content>
      <AccordionTable user={user} isEditable={isEditable} peopleData={peopleData} />
    </Card>
  );
}
Expertise.defaultProps = {
  user: {},
  isEditable: false
};

Expertise.propTypes = {
  user: PropTypes.shape({
    hrId: PropTypes.number
  }),
  isEditable: PropTypes.bool,
  peopleData: PropTypes.shape().isRequired
};
export default Expertise;

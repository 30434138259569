import { expertise } from "redux/constants";

const expertiseReducer = (state = [], action) => {
  switch (action.type) {
    case expertise.FETCH_EXPERTISE_SUCCESS: {
      return [action.data];
    }
    default:
      return state;
  }
};

export default expertiseReducer;

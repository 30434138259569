import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setExpertiseData, setErrorData } from "redux/actions/expertise";

function* handleFetchExpertiseData({ endpoint }) {
  const { data } = yield call(get, endpoint);
  if (!data) {
    yield put(setErrorData(data?.error?.message));
  } else {
    yield put(setExpertiseData(data));
  }
}

export default handleFetchExpertiseData;

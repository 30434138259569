import PropTypes from "prop-types";
import "./styles.scss";

const Label = ({ name, minus }) => {
  return (
    <div className="Label">
      <img
        src={minus ? "./icons/minus-small-circle-filled.svg" : "./icons/plus-small-circle-filled.svg"}
        alt="circle"
        title="circle"
      />
      {name}
    </div>
  );
};
Label.defaultProps = {
  name: "",
  minus: false
};

Label.propTypes = {
  name: PropTypes.string,
  minus: PropTypes.bool
};
export default Label;

import { takeLatest } from "redux-saga/effects";

import { expertise } from "redux/constants";
import handleFetchExpertise from "../worker/handleFetchExpertise";

function* watchExpertise() {
  yield takeLatest(expertise.FETCH_EXPERTISE, handleFetchExpertise);
}

export default watchExpertise;

import { expertise } from "redux/constants";

const getExpertise = (endpoint) => {
  return {
    type: expertise.FETCH_EXPERTISE,
    endpoint
  };
};

const setExpertise = (data) => ({
  type: expertise.FETCH_EXPERTISE_SUCCESS,
  data
});

const setError = (error) => ({
  type: expertise.FETCH_EXPERTISE_FAILURE,
  error
});

const getExpertiseData = (endpoint) => {
  return {
    type: expertise.FETCH_EXPERTISE_DATA,
    endpoint
  };
};

const setExpertiseData = (data) => ({
  type: expertise.FETCH_EXPERTISE_DATA_SUCCESS,
  data
});

const setErrorData = (error) => ({
  type: expertise.FETCH_EXPERTISE_DATA_FAILURE,
  error
});

export { getExpertise, setExpertise, setError, getExpertiseData, setExpertiseData, setErrorData };

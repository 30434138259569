import { call, put } from "redux-saga/effects";
import { get } from "redux/api";
import { setExpertise, setError } from "redux/actions/expertise";

function* handleFetchExpertise({ endpoint }) {
  const { data } = yield call(get, endpoint);
  if (data?.error?.code !== 200) {
    yield put(setError(data?.error?.message));
  } else {
    yield put(setExpertise(data));
  }
}

export default handleFetchExpertise;

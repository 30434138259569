import { expertise } from "redux/constants";

const expertiseLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case expertise.FETCH_EXPERTISE:
      return true;
    case expertise.FETCH_EXPERTISE_SUCCESS:
    case expertise.FETCH_EXPERTISE_FAILURE:
      return false;
    default:
      return state;
  }
};

export default expertiseLoadingReducer;

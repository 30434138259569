import { expertise } from "redux/constants";

const expertiseDataErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case expertise.FETCH_EXPERTISE_DATA_FAILURE:
      return action.error;
    case expertise.FETCH_EXPERTISE_DATA_SUCCESS:
    case expertise.FETCH_EXPERTISE_DATA:
      return null;

    default:
      return state;
  }
};
export default expertiseDataErrorReducer;

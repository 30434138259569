export const initialState = {
  isLoading: false,
  searchResults: []
};

const TaxonomySearchReducer = (state = initialState, action) => {
  switch (action?.type) {
    case "SET_IS_LOADING":
      return { ...state, isLoading: action?.isLoading };
    case "SET_SEARCH_RESULTS":
      return { ...state, searchResults: action?.searchResults };
    case "UPDATE_TERM":
      return {
        ...state,
        searchResults: state?.searchResults?.map((result) => {
          const updatedTermHints = result?.termHints?.map((term) => {
            if (term?.id === action?.term?.id) {
              return { ...term, isAdded: action?.term?.isAdded };
            }
            return term;
          });
          return { ...result, termHints: updatedTermHints };
        })
      };
    default:
      return state;
  }
};

export default TaxonomySearchReducer;

import { Button, List, ListItem, Modal } from "semantic-ui-react";
import { useState } from "react";
import { getParsedToken } from "auth/utils";
import TaxonomySearch from "components/common/TaxonomySearch";
import CONFIG from "constants/config";
import TaxonomyTerm from "components/common/TaxonomySearch/TaxonomyTerm/TaxonomyTerm";
import withPopup from "components/HOCs/withPopup/withPopup";
import TermPath from "components/common/TaxonomySearch/TermPath/TermPath";
import Label from "../common/ExpertiseLabel/Label";
import DragAndDrop from "./DragAndDrop";
import BottomText from "./BottomText";
import "./styles.scss";

const AddExpertise = ({ open, onModalClose }) => {
  const {
    cookies: { SMART_LOGIC_TOKEN }
  } = CONFIG;

  const smartLogicToken = getParsedToken(SMART_LOGIC_TOKEN);

  const apiConfig = {
    headers: {
      authorization: `Bearer ${smartLogicToken?.access_token}`,
      noAuthHeader: true
    },
    apiEndpoint: CONFIG.apiUrl.smartLogicApi
  };

  const tbdbList = [
    { key: "industryPA", title: "Industry", tbdb: "IndustryPracticeArea0" },
    { key: "functionPA", title: "Function", tbdb: "FunctionalPracticeArea" },
    { key: "subjectPA", title: "Subject", tbdb: "Subjects" },
    { key: "toolsAndSolutions", title: "Tools and Solutions", tbdb: "ToolsAndSolutions" }
  ];

  const TermWithPopup = withPopup(TaxonomyTerm);

  const data = [
    {
      id: "1",
      pa: "Insurance",
      title: "Commercial and Reinsurance",
      materialsCount: 12,
      caseCount: 20,
      paaf: "Interested"
    },
    {
      id: "2",
      pa: "Industrial Goods",
      title: "Landscaping in Insurance",
      materialsCount: 12,
      caseCount: 20,
      paaf: "Lead"
    },
    {
      id: "3",
      pa: "Travel Cities and Infrastructure",
      title: "Industrials",
      materialsCount: 12,
      caseCount: 20,
      paaf: "Lead"
    },
    {
      id: "4",
      pa: "Health Care",
      title: "Materials",
      materialsCount: 12,
      caseCount: 20,
      paaf: "Interested"
    },
    {
      id: "5",
      pa: "Financial Institutions",
      title: "Information Technology",
      materialsCount: 12,
      caseCount: 20,
      paaf: "Lead"
    },
    {
      id: "6",
      pa: "Agile",
      title: "Materials",
      materialsCount: 12,
      caseCount: 20,
      paaf: "Interested"
    },
    {
      id: "7",
      pa: "Health Care",
      title: "Materials",
      materialsCount: 12,
      caseCount: 20,
      paaf: "Interested"
    },
    {
      id: "8",
      pa: "Health Care",
      title: "Materials",
      materialsCount: 12,
      caseCount: 20,
      paaf: "Interested"
    },
    {
      id: "9",
      pa: "Health Care",
      title: "Materials",
      materialsCount: 12,
      caseCount: 20,
      paaf: "Interested"
    }
  ];

  const [newlyAddedTerms, setNewlyAddedTerms] = useState([]);
  const [suggestedExpertise] = useState(data);
  const [showSecondPop, setShowSecondPop] = useState(false);
  const [taxonomyActionDispatch, setTaxonomyActionDispatch] = useState(null);

  const handleClose = () => {
    onModalClose();
  };

  const handleAddTerm = (term) => {
    setNewlyAddedTerms((prev) => [...prev, term]);
  };

  const handleRemoveTerm = (term) => {
    setNewlyAddedTerms(newlyAddedTerms.filter((item) => item?.id !== term?.id));
  };

  const nextHandle = () => {
    setShowSecondPop(true);
  };
  const prevHandle = () => {
    setShowSecondPop(false);
  };

  return (
    <div>
      {showSecondPop ? (
        <div className="page2">
          <div>
            <DragAndDrop open={open} onModalClose={onModalClose} prevHandle={prevHandle} data={newlyAddedTerms} />
          </div>
        </div>
      ) : (
        <Modal open={open} onClose={onModalClose} className="addExpertise__popup">
          <Modal.Content>
            <div className="addExpertise__popup-heading">
              <div>
                <h1>Add your Expertise in just two steps</h1>
                <p className="subHeading"> Search and add any number of expertise</p>
              </div>
              <div>
                <img
                  src="./icons/cross-icon-black.svg"
                  onClick={handleClose}
                  className="cancel_icon"
                  alt="close"
                  title="close"
                  role="presentation"
                />
              </div>
            </div>

            <div className="page1">
              <TaxonomySearch
                placeholder="Search for expertise to add"
                apiConfig={apiConfig}
                tbdbList={tbdbList}
                onAddTerm={handleAddTerm}
                onRemoveTerm={handleRemoveTerm}
                setTaxonomyActionDispatch={setTaxonomyActionDispatch}
                prevAddedTerms={newlyAddedTerms}
              />
              {!!suggestedExpertise?.length && (
                <div className="addExpertise__popup-suggestedExpertise">
                  <p>
                    <img src="./icons/react-logo.svg" alt="react-logo" title="logo" /> Suggested Expertise based on your
                    recent cases, profile summary
                  </p>
                  <div className="expertise_section">
                    {suggestedExpertise.map((expertise) => {
                      return (
                        <div key={`${expertise?.pa}_${expertise.id}`}>
                          <div>
                            <Label name={expertise?.pa} plus="true" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {!!newlyAddedTerms?.length > 0 && (
                <div className="addExpertise__popup-additional_section">
                  <p> Added Expertise</p>
                  <div className="expertise_section">
                    <List horizontal>
                      {newlyAddedTerms?.map((term) => (
                        <ListItem key={term?.id}>
                          <TermWithPopup
                            popupContent={<TermPath path={term?.path} />}
                            term={term}
                            onRemoveTerm={handleRemoveTerm}
                            dispatch={taxonomyActionDispatch?.dispatch}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                  <div className="align-items-center text-align-center">
                    <Button className="next_button" onClick={nextHandle}>
                      Next Step
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <BottomText />
          </Modal.Content>
        </Modal>
      )}
    </div>
  );
};

export default AddExpertise;

import React from "react";
import { Checkbox } from "semantic-ui-react";
import "./styles.scss";

const ExpertiseFilters = ({ checkboxes }) => {
  return (
    <div className="filters__items">
      {checkboxes?.map((checkbox) => (
        <div key={checkbox.value} className="filters__item">
          <Checkbox label={checkbox.label} value={checkbox.value} />
        </div>
      ))}
    </div>
  );
};

export default ExpertiseFilters;

import { expertise } from "redux/constants";

const expertiseErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case expertise.FETCH_EXPERTISE_FAILURE:
      return action.error;
    case expertise.FETCH_EXPERTISE_SUCCESS:
    case expertise.FETCH_EXPERTISE:
      return null;
    default:
      return state;
  }
};

export default expertiseErrorReducer;

/* eslint-disable no-nested-ternary */
import LABEL from "constants/label";
import { isNil, merge } from "lodash";
import { sortFilterOptions, copyObject } from "pages/helpers/utils";
import { isEmptyOrNull } from "helpers/utils";
import { Image, Placeholder } from "semantic-ui-react";

const { AFFILIATIONS_PAGE } = LABEL;

function getProficiency(id, isEditable) {
  let proficiency = "";
  let imageSrc = "";

  if (id === 1) {
    proficiency = AFFILIATIONS_PAGE.ThoughtLeader;
    imageSrc = "/icons/100-Expert.svg";
  } else if (id === 2) {
    proficiency = AFFILIATIONS_PAGE.Advanced;
    imageSrc = "/icons/75-Advanced.svg";
  } else if (id === 3) {
    proficiency = AFFILIATIONS_PAGE.Experienced;
    imageSrc = "/icons/50-Experienced.svg";
  } else if (id === 4) {
    proficiency = AFFILIATIONS_PAGE.BASIC;
    imageSrc = "/icons/25-Beginner.svg";
  } else {
    proficiency = "-";
  }
  return isEditable ? (
    proficiency
  ) : (
    <>
      <Image src={imageSrc} className="mr-h" /> {proficiency}
    </>
  );
}

const getTopicCount = (isLoading, topics) => {
  return isLoading ? (
    <Placeholder className="countLoading">
      <Placeholder.Line />
    </Placeholder>
  ) : (
    topics?.reduce((acc, currentRecord) => acc + currentRecord?.children?.length, 0)
  );
};

const getProficiencyListFromTopics = (topics = []) => {
  const proficiencyList = topics.reduce((accumulator, currentValue) => {
    const proficiency = currentValue && currentValue.proficiency;
    if (!accumulator.find((acc) => acc === currentValue.proficiency) && proficiency) accumulator.push(proficiency);
    return accumulator;
  }, []);
  return proficiencyList;
};

const getRolesFromTopics = (topics = []) => {
  const topicRoles = topics.reduce((accumulator, currentValue) => {
    const designatedBy = currentValue && currentValue.designatedBy;
    if (!accumulator.find((acc) => acc === currentValue.designatedBy) && designatedBy)
      accumulator.push({ designatedBy: currentValue.designatedBy, designatedByType: currentValue.designatedByType });
    return accumulator;
  }, []);
  return topicRoles;
};

const getProficiencyFilterData = (data, ids) => {
  const filterData = [];
  data?.forEach((topic) => {
    ids.forEach((id) => {
      topic?.children?.forEach((child) => {
        if (child.proficiency === id) {
          if (!filterData?.some((record) => record?.parentNode === topic?.parentNode)) {
            filterData.push({ ...topic, children: [] });
          }
          filterData[filterData.length - 1].children.push(child);
        }
      });
    });
  });
  return filterData;
};

const getRolesFilterData = (data, roleName) => {
  const filterData = [];
  data.forEach((topic) => {
    roleName.forEach((name) => {
      topic?.children?.forEach((child) => {
        if (child?.designatedByType === name || child?.designatedByType?.includes(name)) {
          if (!filterData?.some((record) => record?.parentNode === topic?.parentNode)) {
            filterData.push({ ...topic, children: [] });
          }
          if (filterData[filterData.length - 1]?.children?.filter((record) => record === child)?.length === 0) {
            filterData[filterData.length - 1]?.children.push(child);
          }
        }
      });
    });
  });
  return filterData;
};

const getTopicAndSkillsCount = (items) => {
  let counter = 0;
  // loop through the passed topics/skills array.
  items?.forEach((item) => {
    item.children?.forEach((child) => {
      counter += 1;
    });
  });
  return counter;
};

const getProficiencyListForFilter = (topicData, skillData) => {
  let proficiencyListTopics = [];
  let proficiencyListSkills = [];
  topicData.forEach((dataItem) => {
    const proficiencyPerTopic = getProficiencyListFromTopics(dataItem.children);
    proficiencyListTopics = [...proficiencyListTopics, ...proficiencyPerTopic];
  });
  skillData.forEach((dataItem) => {
    const proficiencyPerTopic = getProficiencyListFromTopics(dataItem.children);
    proficiencyListSkills = [...proficiencyListSkills, ...proficiencyPerTopic];
  });
  let allProficiency = [];
  allProficiency = [...proficiencyListTopics, ...proficiencyListSkills];
  const uniqueProficiencyList = allProficiency.reduce((accumulator, currentValue) => {
    const totalSkillsAndTopics =
      getTopicAndSkillsCount(getProficiencyFilterData(topicData, [currentValue])) +
      getTopicAndSkillsCount(getProficiencyFilterData(skillData, [currentValue]));
    const proficiencyItem = {
      key: currentValue,
      value: currentValue,
      textForAdobeAnalytics: getProficiency(currentValue, true),
      text: `${getProficiency(currentValue, true)} (${totalSkillsAndTopics})`,
      sortOrder: totalSkillsAndTopics
    };
    if (!accumulator.find((acc) => acc.value === currentValue)) {
      accumulator.push(proficiencyItem);
    }
    return accumulator.sort(sortFilterOptions);
  }, []);
  return uniqueProficiencyList;
};

const getRolesListForFilter = (topicData, skillData) => {
  let rolesTopics = [];
  let rolesSkills = [];
  const newTopicRoles = [];
  topicData.forEach((dataItem) => {
    const rolesPerTopic = getRolesFromTopics(dataItem.children);
    rolesTopics = [...rolesTopics, ...rolesPerTopic];
  });
  // multiple topic roles handling
  const updatedTopicRoles = rolesTopics?.map((role) => {
    const updatedRole = { ...role };
    if (!isEmptyOrNull(updatedRole?.designatedByType) && updatedRole?.designatedByType?.indexOf(",") !== -1) {
      const rolesArray = updatedRole?.designatedByType?.split(",");
      rolesArray?.forEach((newRole, i) => {
        if (i > 0) newTopicRoles.push({ designatedBy: "new", designatedByType: newRole });
      });
      // eslint-disable-next-line prefer-destructuring
      updatedRole.designatedByType = rolesArray?.length > 0 && rolesArray[0];
    }
    return updatedRole;
  });

  skillData.forEach((dataItem) => {
    const rolesPerTopic = getRolesFromTopics(dataItem.children);
    rolesSkills = [...rolesSkills, ...rolesPerTopic];
  });

  let allRoles = [];
  allRoles = [...rolesSkills, ...newTopicRoles, ...updatedTopicRoles];
  const uniqueRolesList = allRoles.reduce((accumulator, currentValue) => {
    const totalSkillsAndTopics =
      getTopicAndSkillsCount(getRolesFilterData(topicData, [currentValue.designatedByType])) +
      getTopicAndSkillsCount(getRolesFilterData(skillData, [currentValue.designatedByType]));
    const roleItem = {
      key: currentValue.designatedBy,
      value: currentValue.designatedByType,
      textForAdobeAnalytics: currentValue.designatedByType,
      text: `${currentValue.designatedByType} (${totalSkillsAndTopics})`,
      sortOrder: totalSkillsAndTopics
    };
    if (
      !accumulator.find(
        (acc) => acc.textForAdobeAnalytics?.toLowerCase() === currentValue.designatedByType?.toLowerCase()
      )
    ) {
      accumulator.push(roleItem);
    }
    return accumulator.sort(sortFilterOptions);
  }, []);
  return uniqueRolesList;
};

/**
 * Check if object has valid data
 * @param {Array} object
 * @returns {Boolean}
 */
const checkProperties = (obj) => {
  const objectCopy = [...obj];
  return !objectCopy.filter((element) => !isNil(element)).length;
};

const groupBy = (inputArray, func) => {
  return inputArray
    ?.filter(
      (record) =>
        !isEmptyOrNull(record?.guid) && !isEmptyOrNull(record?.parentNode) && !isEmptyOrNull(record?.displayName)
    )
    .reduce(
      // eslint-disable-next-line
      (acc, item, index, arr, parentNode = func(item)) => ((acc[parentNode] || (acc[parentNode] = [])).push(item), acc),
      {}
    );
};

const convertFlatArrayToNested = (array) => {
  const newArray = [];
  const resultGrouped = groupBy(array, (item) => {
    return item.parentNode;
  });

  Object.keys(resultGrouped)?.forEach((key) => {
    newArray.push({
      parentNode: key,
      children: resultGrouped[key]
    });
  });
  newArray?.sort((topicOne, topicTwo) => {
    if (topicOne?.parentNode < topicTwo?.parentNode) return -1;
    if (topicOne?.parentNode > topicTwo?.parentNode) return 1;
    return 0;
  });
  return newArray;
};

/**
@description this function adds new row 
@param data as an array
@returns updated data
*/

const addRow = (data) => {
  const childRecord = {
    displayName: "",
    guid: "",
    proficiency: "",
    designatedById: 1,
    designatedByType: "Self Designated",
    new: true,
    id: Date.now()
  };

  const parentRecord = {
    parentNode: "",
    new: true,
    children: [childRecord]
  };

  const tableData = copyObject(data);
  if (tableData[0]?.new) {
    tableData[0].children.unshift(childRecord);
  } else {
    tableData.unshift(parentRecord);
  }
  return tableData;
};

const getEditableData = (data) => {
  const newState = [];
  data?.forEach((item, i) => {
    if ((item.new && item.children.length > 0) || item.children?.some((obj) => obj.edited)) {
      newState.push(item);
    }
  });
  return newState;
};

const getExistingGuids = (filterApplied, data, viewData) => {
  const existingGuids = [];
  const completeData = copyObject(filterApplied ? [...data, ...viewData] : data);
  completeData?.forEach((record) => {
    record?.children?.forEach(
      (child) => child.guid && existingGuids.push({ displayName: child.displayName, guid: child.guid })
    );
  });
  return existingGuids;
};

const retainInitialState = (_data, data) => {
  const updatedData = copyObject(_data);
  const originalData = copyObject(data);
  const newData = updatedData?.map((topic) => {
    const parentIndex = originalData.findIndex((obj) => obj.parentNode === topic.parentNode);
    if (parentIndex !== -1) {
      topic?.children?.map((childTopic) => {
        const childIndex = originalData[parentIndex]?.children?.findIndex((obj) => obj.guid === childTopic.guid);
        return (
          childIndex !== -1 && merge(childTopic, originalData[parentIndex].children[childIndex], { edited: false })
        );
      });
    }
    return topic;
  });
  return newData;
};

const deleteRecordFromState = (data, parentIndex, childIndex) => {
  const newData = copyObject(data);
  newData[parentIndex]?.children?.splice(childIndex, 1);
  if (newData[parentIndex]?.children?.length === 0) {
    newData.splice(parentIndex, 1);
  }
  return newData;
};

// merge expertise api and people api topic data to show multiple roles
const updateExpertiseData = (expertiseData, peopleData, businessSubjectsTaxonomyData) => {
  const newExpertiseData = [];
  const updatedExpertiseApiData = copyObject(expertiseData);
  const peopleApiData = copyObject(peopleData);
  peopleApiData?.forEach((topic) => {
    topic?.bstTags?.guids.map((guid) => {
      const expertiseDataIndex = updatedExpertiseApiData.findIndex((record) => record.guid === guid);
      const peopleApiIndex = newExpertiseData.findIndex((record) => record.guid === guid);

      // set multiple roles into expertise api data
      if (expertiseDataIndex !== -1) {
        const updatedRoles = isEmptyOrNull(updatedExpertiseApiData[expertiseDataIndex]?.designatedByType)
          ? topic?.roleName
          : `${updatedExpertiseApiData[expertiseDataIndex]?.designatedByType},${topic.roleName}`;

        const updatedRolesArray = [...new Set(updatedRoles?.split(","))].filter(
          (item) => item.toLowerCase() !== "self designated"
        );
        updatedExpertiseApiData[expertiseDataIndex].multipleRoles = !isEmptyOrNull(updatedRolesArray);
        updatedExpertiseApiData[expertiseDataIndex].designatedByType = updatedRolesArray?.toString();
      }
      //  create new expertise from people api data and set multiple roles
      else {
        const taxonomyTopicData = businessSubjectsTaxonomyData?.terms?.filter((el) => el.term.id === guid)[0]?.term;
        const newTopic = {};
        const newExpertiseRole =
          newExpertiseData[peopleApiIndex]?.designatedByType?.split(",").findIndex((el) => el === topic.roleName) ===
          -1;
        if (peopleApiIndex === -1) {
          newTopic.name = taxonomyTopicData?.name;
          newTopic.guid = guid;
          newTopic.displayName = taxonomyTopicData?.displayName;
          newTopic.parentNode =
            taxonomyTopicData?.paths && taxonomyTopicData?.paths[0]?.path[1]?.field?.name
              ? taxonomyTopicData?.paths && taxonomyTopicData?.paths[0]?.path[1]?.field?.name
              : taxonomyTopicData?.displayName;
          newTopic.designatedByType = topic.roleName;
          newTopic.designatedBy = "new";
          newExpertiseData.push(newTopic);
        } else if (newExpertiseData.length > 0 && newExpertiseRole && peopleApiIndex !== -1) {
          newExpertiseData[peopleApiIndex].multipleRoles = true;
          newExpertiseData[
            peopleApiIndex
          ].designatedByType = `${newExpertiseData[peopleApiIndex].designatedByType},${topic.roleName}`;
        }
      }
      return guid;
    });
    return topic;
  });
  return [...updatedExpertiseApiData, ...newExpertiseData];
};

export {
  getProficiency,
  getProficiencyListForFilter,
  getRolesListForFilter,
  getProficiencyFilterData,
  getRolesFilterData,
  getProficiencyListFromTopics,
  getRolesFromTopics,
  checkProperties,
  getTopicAndSkillsCount,
  convertFlatArrayToNested,
  addRow,
  getEditableData,
  getExistingGuids,
  retainInitialState,
  deleteRecordFromState,
  updateExpertiseData,
  getTopicCount
};
